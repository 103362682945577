<template>
  <div>
    <div class="mass-action-settings">
      <div>
        <cards-stage-select
          v-model="actionData.stage_type"
          placeholder="Выберите этап"
          size="small"
          style="width: 300px"
        ></cards-stage-select>
      </div>
      <div>
        <cards-stage-negotiation-select
          v-model="actionData.negotiation_type"
          style="margin-top:10px; width: 300px"
          placeholder="Выберите шаг для удаления"
          size="small"
        ></cards-stage-negotiation-select>
      </div>
      <div>
        <el-checkbox
          v-model="multipleNegotiations"
          style="margin-top:10px; width: 300px"
        >
          Может быть несколько таких шагов в этапе
        </el-checkbox>
      </div>

      <div v-show="multipleNegotiations">
        Порядковый номер такого шага в этапе
        <el-input-number
          v-model="actionData.order"
          size="small"
          :min="1"
          :step="1"
          style="margin-left:10px"
        ></el-input-number>
      </div>

      <el-popconfirm

        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите удалить шаг?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          style="margin-top: 20px"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Запустить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";

export default {
  name: "card-delete-negotiation-action",
  mixins: [ActionTemplateMixin],
  components: {CardsStageNegotiationSelect, MassActionResult, CardsStageSelect},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.stage_type
        || !this.actionData.negotiation_type;
    }
  },
  data() {
    return {
      multipleNegotiations: false,
      actionData: {
        stage_type: null,
        negotiation_type: null,
        order: null,
      }
    }
  },
  methods: {},
}
</script>
